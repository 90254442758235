import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore"
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config"

const firebaseConfig = {
  apiKey: "AIzaSyBJOOTc7jfdfvESurYAj7ZGr-UfKWVkbgE",
  authDomain: "linktree-igreja-amplitude.firebaseapp.com",
  projectId: "linktree-igreja-amplitude",
  storageBucket: "linktree-igreja-amplitude.appspot.com",
  messagingSenderId: "539965115458",
  appId: "1:539965115458:web:2353814c7d3318b92ef1db",
  measurementId: "G-05H570VF7D"
};

export const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);

export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 0;

fetchAndActivate(remoteConfig)