import React, { useEffect, useState } from "react"
import logo from "./img/Amplitude_logo-branco.png"
import deezerLogoDark from "./img/deezer-dark.png"
import deezerLogo from "./img/deezer-white.png"
import mapMarker from "./img/map_marker.png"
import spotifyLogo from "./img/Spotify_White.png"
import ytLogo from "./img/yt_dark.png"
import ytLogoDark from "./img/yt_light.png"

import instagramLogo from "./img/instagram.png"

import applePodcastLogo from "./img/apple-podcast.png"
import googlePodcastLogo from "./img/google-podcast.png"

import { Carousel, Col, Container, Row } from 'react-bootstrap'

import "./App.css"

import { isIOS, isMobile } from "react-device-detect"

import Youtube from "./services/yt"

import ReactGA from "react-ga"

import { doc, onSnapshot } from "firebase/firestore"
import { firestore } from "./utils/firebase"

ReactGA.initialize("UA-149476177-2")

const appleUrl =
  "https://podcasts.apple.com/us/podcast/igreja-amplitude/id1546573626"
const deezerUrl = "https://www.deezer.com/en/show/2115732"
const googleUrl =
  "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80NTE5MzUxYy9wb2RjYXN0L3Jzcw=="
const spotifyUrl = "https://open.spotify.com/show/5XfKvvAtcHr0hQOuZMETMz"
const youtubeUrl =
  "https://www.youtube.com/channel/UCfrzmjAWLswNcG1xDDupIyA?sub_confirmation=1"
const instagramUrl = "http://instagram.com/amplitudevinhedo/"

const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search)
  }
  return new URLSearchParams()
}

const getQueryStringVal = (key) => {
  return getQuery().get(key)
}

function App() {
  const [videos, setVideos] = useState([])
  const [homeBanners, setBanners] = useState([])
  const _busca = async () => {
    const { data } = await Youtube.get(
      "playlistItems?playlistId=UUfrzmjAWLswNcG1xDDupIyA&part=snippet",
      {
        params: {
          q: "Igreja Amplitude Vinhedo",
        },
      }
    )
    setVideos(data.items)
  }

  const activateQR = !!getQueryStringVal("qrcode")
  const activateQRyt = !!getQueryStringVal("qrcodeyoutube")

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    _busca()
  }, [])

  const getEventAndRedirect = (action, url) => {
    ReactGA.event({
      category: "click",
      action: action,
    })
    window.location.href = url
  }

  const goSpotify = () => {
    getEventAndRedirect("Spotify", spotifyUrl)
  }

  const goYoutube = () => {
    getEventAndRedirect("Youtube", youtubeUrl)
  }

  const goYoutubeVideo = (snippet) => {
    const url = 'https://www.youtube.com/watch?v=' + snippet.resourceId.videoId
    getEventAndRedirect("Youtube - Video", url)
  }

  const goApple = () => {
    getEventAndRedirect("Apple Podcast", appleUrl)
  }

  const goGoogle = () => {
    getEventAndRedirect("Google Podcast", googleUrl)
  }

  const goDeezer = () => {
    getEventAndRedirect("Deezer", deezerUrl)
  }

  const goLocation = () => {
    getEventAndRedirect("Locations", locationsUrl)
  }

  const goInstagram = () => {
    getEventAndRedirect("Instagram", instagramUrl)
  }

  const locationsUrl = isMobile
    ? (isIOS ? `maps:` : `geo:`) +
    "-23.0338331,-46.9786268?q=Avenida João Pescarini, 46"
    : "https://goo.gl/maps/NvrnPe6J8WLQvmXEA"


  const getThumbUrl = (snippet) => {
    const { thumbnails } = snippet

    return thumbnails.maxres ? thumbnails.maxres.url : thumbnails.high.url
  }

  useEffect(() => {
    const unsub = onSnapshot(doc(firestore, "banners", "home"), (doc) => {
      const {banners} = doc.data()
      setBanners(banners.filter(item => item.isActive))
    });

    return () => unsub();

  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Carousel fade indicators={false} className="App-Video" style={{ padding: '15px' }}>
        {Boolean(homeBanners.length) && homeBanners?.map((item, index) => (
          <Carousel.Item key={index} onClick={() => { getEventAndRedirect('banner', item.url) }} interval={8000}>
            <img
              className="d-block w-100 App-Video"
              src={item.img}
              alt="Inscription"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
        ))}

        {videos.map((video) => (
          <Carousel.Item key={video.id} onClick={() => goYoutubeVideo(video.snippet)} className="App-Video">
            <img
              className="d-block w-100 App-Video"
              src={getThumbUrl(video.snippet)}
              alt={video.snippet.title}
            />
            <div className="play-button" />
            <div className="play-button-box" />
            <Carousel.Caption className="video-title App-Video">
              <p className="text-truncate">{video.snippet.title}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <br />
      <Container>
        <Row className="align-items-center">
          {(activateQR || activateQRyt) && (
            <Col xs={4}>
              <span onClick={goInstagram} className="App-link apple">
                <img alt="instagram" className="yt-light" src={instagramLogo} />
              </span>
            </Col>
          )}
          {!activateQRyt && (
            <Col xs={4}>
              <span onClick={goYoutube} className="App-link youtube">
                <img alt="youtube" className="yt-light" src={ytLogo} />
                <img alt="youtube" className="yt-dark" src={ytLogoDark} />
              </span>
            </Col>
          )}
          <Col xs={4}>
            <span onClick={goSpotify} className="App-link spotify">
              <img alt="spotify" src={spotifyLogo} />
            </span>
          </Col>
          <Col xs={4}>
            <span onClick={goApple} className="App-link apple">
              <img
                alt="applePodcast"
                className="yt-light"
                src={applePodcastLogo}
              />
            </span>
          </Col>
          <Col xs={4}>
            <span onClick={goGoogle} className="App-link google">
              <img
                alt="googlePodcast"
                className="yt-light"
                src={googlePodcastLogo}
              />
            </span>
          </Col>
          <Col xs={4}>
            <span onClick={goDeezer} className="App-link deezer">
              <img alt="deezer" className="yt-light" src={deezerLogo} />
              <img alt="deezer" className="yt-dark" src={deezerLogoDark} />
            </span>
          </Col>
          {!activateQR && (
            <Col xs={4}>
              <span onClick={goLocation} className="App-link">
                <img src={mapMarker} className="App-map-marker" alt="logo" /> Como
                chegar
              </span>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default App
